import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
`
export const StyledImg = styled.img`
  max-width: 120px;
  max-height: 120px;
  margin-bottom: 30px;
`