import React, { FC, useContext, useEffect, useState } from 'react';
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import GenresSelect from "../components/GenresSelect/GenresSelect";
import MoviesList, { IMovieRes } from "../components/MoviesList/MoviesList";
import { useTranslation } from "react-i18next";
import useFetchFavoriteGenres from "../hooks/useFetchFavoriteGenres";
import useFetchFavoriteMovies from "../hooks/useFetchFavoriteMovies";
import { AuthContext } from "../contexts/AuthContext/AuthContext";


const MainPage: FC = () => {
  const {t, i18n} = useTranslation();
  const {res: favoriteGenres} = useFetchFavoriteGenres();

  const navigate = useNavigate();
  const [moviesData, setMoviesData] = useState<IMovieRes[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [favoriteMoviesRes, getFavoriteMoviesLoading, ] = useFetchFavoriteMovies(currentPage, i18n.language)
  const {authData} = useContext(AuthContext);

  useEffect(() => {
    if(!getFavoriteMoviesLoading)
    {
      setMoviesData(favoriteMoviesRes.getFavoriteMovies.movies)
      setTotalPages(favoriteMoviesRes.getFavoriteMovies.totalPages > 500 ? 500 : favoriteMoviesRes.getFavoriteMovies.totalPages)
    }
  }, [currentPage, favoriteMoviesRes]);

  useEffect(() => {
    !authData && navigate("/auth");
  }, []);

  return (
    <Container>
      <GenresSelect selectedGenres={favoriteGenres}/>
      <MoviesList totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage}
                  moviesData={moviesData}
                  setMoviesData={setMoviesData} headerTitle={t("mainPage.favoriteMoviesTitle")}
                  isForOutput/>
    </Container>
  );
};

export default MainPage;