import styled from "styled-components";
import { Avatar, ListItem } from "@mui/material";

interface IStyledListItemProps {
  isMarked: boolean;
}

export const WrapperTextItem = styled.div`
  display: flex;
  flex-direction: column;
`
export const StyledListItem = styled(ListItem)<IStyledListItemProps>`
  background-color: ${({isMarked}) => isMarked ? "#ebebebff" : "unset"};
  border: 1px solid #dedede; 
  border-radius: 20px;
  margin: 10px 0;
  padding: 10px;
`

export const StyledAvatar = styled(Avatar)`
  width: 100px;
  height: 100px;
  margin: 0 10px;
`