import { gql, useQuery } from "@apollo/client";

export const GET_FAVORITE_GENRES = gql`
  query {
   getUser {
     favoriteGenres {
       id
       genreId
     }
    }
  }
`

export default function useFetchFavoriteGenres() {
  const {data, loading, error} = useQuery(GET_FAVORITE_GENRES);

  return {res: data ? data.getUser.favoriteGenres.map((genre: {genreId: number}) => genre.genreId) : [], loading, error};
}