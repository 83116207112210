import { gql, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

export default function useSignIn() {
  const SIGN_IN = gql`
    mutation signIn($param: UserDataInput!) {
      signIn(userData: $param) {
        accessToken
        user {
          id
          login
        }
      }
    }
  `
  const [signIn, {data, loading, error}] = useMutation(SIGN_IN);
  const navigate = useNavigate();

  async function signInCallback(login: string, password: string) {
    try {
    const res = await signIn({variables: {param: {login, password}}});
    if (res.data.signIn) {
      navigate("/");
      return res.data.signIn;
    }
    }
    catch(err) {
      console.error(err)
      return false;
    }
  }

  return [signInCallback, data, loading, error];
}