import { gql, useQuery } from "@apollo/client";

export const GET_FAVORITE_MOVIES = gql`
  query getFavoriteMovies($param: GetFavoriteMoviesInput!) {
    getFavoriteMovies(getFavoriteMovies: $param) {
      totalPages
      movies {
        isWatched
        userId
        data {
          movieId
          title
          overview 
          posterPath
          voteAverage
        }
      }
    }
  }
`
export default function useFetchFavoriteMovies(page: number, language?: string) {
  const {data, loading, error} = useQuery(GET_FAVORITE_MOVIES, {
    variables: {
      param: {
        page,
        language
      }
    }
  });
  return [data, loading, error];
}