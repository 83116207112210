import styled from "styled-components";
import TextField from "@mui/material/TextField";

export const FormContainer = styled.div`
  box-shadow: 0 4px 20px 3px rgba(34, 60, 80, 0.2);
  padding: 30px;
  border-radius: 10px;
`
export const StyledTextField = styled(TextField)`
  && {
    width: 19rem;
    margin-bottom: 0.8rem;
  }
`
export const Error = styled.div`
  color: red;
  margin: -0.8rem 0 0.8rem 0.3rem;
`