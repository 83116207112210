import { gql, useQuery } from "@apollo/client";

export const GET_SEARCH_MOVIES = gql`
  query searchMovies($param: GetMoviesDataInput) {
    getMoviesList(getMoviesData: $param) {
      totalPages
      movies {
        isWatched
        userId
        data {
          movieId
          title
          overview
          posterPath
          voteAverage
        }
      }
    }
  }
`

export default function useFetchSearchMovies(page?: number, year?: string, rating?: number, genres?: string, language?: string) {
  const {data, loading, error} = useQuery(GET_SEARCH_MOVIES, {
    variables: {
      param: {
        page,
        year,
        rating,
        genres,
        language
      }
    }
  });

  return [data, loading, error];
}