import { gql, useQuery } from "@apollo/client";

export default function useFetchFilters() {
  const GET_FILTERS = gql`
    query getFilters {
      getFilters
    }
  `
  const {data, loading, error} = useQuery(GET_FILTERS);
  return [data, loading, error];
}