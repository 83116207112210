import React, { FC } from 'react';
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { StyledList } from "./styles";
import { useTranslation } from "react-i18next";
import useFetchGenres from "../../hooks/useFetchGenres";
import useUpdateFavoriteGenres from "../../hooks/useUpdateFavoriteGenres";

export interface IGenre {
  id: string;
  name: string;
}

interface IGenresSelectProps {
  selectedGenres: number[];
}

const GenresSelect: FC<IGenresSelectProps> = ({selectedGenres}) => {

  const {t, i18n} = useTranslation();
  const {res: allGenres, error} = useFetchGenres(i18n.language);
  const {updateFavoriteGenres} = useUpdateFavoriteGenres();

  const handleGenres = async (
    event: React.MouseEvent<HTMLElement>,
    newGenresIds: number[],
  ) => {
    await updateFavoriteGenres(newGenresIds);
  };
  if (!allGenres) {
    if (error)
      return <div>error</div>;
    return <div>loading</div>
  }
  return (
    <>
      <Typography sx={{mt: "20px", fontSize: "1rem"}}
                  variant="h5">{t("mainPage.selectGenresTitle")}</Typography>
      <StyledList style={{maxWidth: "100%", overflow: 'auto'}}>
        <ToggleButtonGroup
          value={selectedGenres}
          onChange={handleGenres}
        >
          {allGenres.map((genre: IGenre) =>
            <ToggleButton key={genre.id + genre.name}
                          value={parseInt(genre.id)}
                          aria-label={genre.name}>
              {genre.name}
            </ToggleButton>)}

        </ToggleButtonGroup>
      </StyledList>
    </>
  )
}

export default GenresSelect;