import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink
} from '@apollo/client';
import { GlobalStyles } from "./assets/globalStyles";
import './i18n';
import { setContext } from "@apollo/client/link/context";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { onError } from "@apollo/client/link/error";
import { UNAUTHORIZED_CODE_ERROR } from "./constants/errors";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BACKEND_URI
});

const authLink = setContext((_, {headers}) => {
  const token = localStorage.getItem('token');

  if (token) {
    const decodedToken = jwtDecode<JwtPayload>(token);
    if (decodedToken.exp && decodedToken.exp * 1000 < Date.now())
      localStorage.clear();
  }
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const errorLink = onError(({graphQLErrors}) => {
  if (graphQLErrors) {
    graphQLErrors.map((err) => {
        const error = err.extensions.originalError as (Error & { statusCode: number });
        if (error && error.statusCode === UNAUTHORIZED_CODE_ERROR) {
          window.location.href = "/auth";
          client.clearStore();
          localStorage.clear();
        }
      }
    );
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      GenreModel: {
        keyFields: ["id", "name"]
      },
      FavoriteMovieModel: {
        keyFields: ["data", "isWatched"]
      },
      genres: {
        keyFields: ["id", "genreId"]
      },
      MovieModel: {
        keyFields: ["isWatched", "data"]
      },
      MoviesListModel: {
        keyFields: ["totalPages", "movies"]
      }
    }
  })
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <GlobalStyles/>
          <App/>
        </BrowserRouter>
      </Suspense>
    </ApolloProvider>
  </React.StrictMode>
);
