import { gql, useMutation } from "@apollo/client";
import { GET_FAVORITE_MOVIES } from "./useFetchFavoriteMovies";

export default function useSwitchWatchMovie(movieId: number) {
  const SWITCH_WATCH_MOVIE = gql`
  mutation switchWatchMovie($param: ModifyFavoriteMovieInput!) {
   switchWatchMovie(switchWatchMovie: $param) {
     movieId
     isWatched
   }
  }
`
  const [switchWatchMovie, {data, loading, error}] = useMutation(SWITCH_WATCH_MOVIE, {
    variables: {param: {movieId}},
    refetchQueries: [GET_FAVORITE_MOVIES, "getFavoriteMovies"]
  });

  return [switchWatchMovie, data, loading, error];
}