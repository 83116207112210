import { gql, useMutation } from "@apollo/client";
import { GET_FAVORITE_MOVIES } from "./useFetchFavoriteMovies";
import { GET_SEARCH_MOVIES } from "./useFetchSearchMovies";

export default function useAddFavoriteMovies(movieId: number) {
  const ADD_FAVORITE_MOVIE = gql`
  mutation addFavoriteMovie($param: ModifyFavoriteMovieInput!) {
   addFavoriteMovie(addFavoriteMovie: $param) {
      id
      isWatched
      movieId
    }
  }
`
  const [addFavoriteMovie, {data, loading, error}] = useMutation(ADD_FAVORITE_MOVIE, {
    variables: {param: {movieId}},
    refetchQueries: [GET_FAVORITE_MOVIES, "getFavoriteMovies", GET_SEARCH_MOVIES, "searchMovies"]
  });

  return [addFavoriteMovie, data, loading, error];
}