import React, { FC } from 'react';
import { IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { IMovie, IMovieRes } from "./MoviesList/MoviesList";
import useRemoveFavoriteMovie from "../hooks/useRemoveFavoriteMovie";
import useSwitchWatchMovie from "../hooks/useSwitchWatchMovie";

interface IMovieButtonsProps {
  movieInfo: IMovie;
  moviesData: IMovieRes[];
  setMoviesData: (value: IMovieRes[]) => void;
  isWatched: boolean;
  setIsWatched: (value: boolean) => void;
}

const MovieOutputButtons: FC<IMovieButtonsProps> = ({movieInfo, moviesData, setMoviesData, isWatched, setIsWatched}) => {

  const [removeFavoriteMovie] = useRemoveFavoriteMovie(movieInfo.movieId);
  const [switchIsWatched] = useSwitchWatchMovie(movieInfo.movieId);

  const handleDelete: React.MouseEventHandler<HTMLButtonElement> = async () => {
    await removeFavoriteMovie();
    const newMoviesData: IMovieRes[] = moviesData.filter((movie) => movie.data.movieId !== movieInfo.movieId);
    setMoviesData(newMoviesData);
  }

  const handleCheck: React.MouseEventHandler<HTMLButtonElement> = async () => {
    await switchIsWatched();
    setIsWatched(!isWatched);
  }

  return (
    <>
      <IconButton onClick={handleCheck}>
        <CheckIcon fontSize="large" sx={{color: "green"}}/>
      </IconButton>
      <IconButton onClick={handleDelete}>
        <ClearIcon fontSize="large" sx={{color: "red"}}/>
      </IconButton>
    </>
  );
};

export default MovieOutputButtons;