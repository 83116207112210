import React, { FC, useEffect } from 'react';
import LoginForm from "../../components/LoginForm/LoginForm";
import { StyledContainer, StyledImg } from "./styles";
import { useNavigate } from "react-router-dom";

const logo: string = require("../../assets/img/logo.svg").default;


const AuthPage: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if(localStorage.getItem("token"))
      navigate("/");
  }, []);

  return (
    <StyledContainer>
      <StyledImg src={logo} alt="logo"/>
      <LoginForm/>
    </StyledContainer>
  );
};

export default AuthPage;