import styled from "styled-components";
import { Container } from "@mui/material";


export const StyledWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`
export const Main = styled(Container)`
  flex: 1 1 auto;
  display: flex;
`