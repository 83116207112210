import styled from "styled-components";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";


export const Buttons = styled.div`
  display: flex;
`
export const FavoriteMovieHeader = styled.div`
  display: flex;
  margin-top: 20px;
`
export const FavoriteMoviesTitle = styled(Typography)`
  flex: 1 1 auto;
  align-self: center;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #0303ce;
  border: 1px solid;
  margin: 0 10px;
  border-radius: 5px;
  padding: 10px 15px;
  text-transform: uppercase;
`