import { gql, useQuery } from "@apollo/client";

export const GET_GENRES = gql`
  query getGenres($param: GetGenresInput) {
    getGenres(language: $param) {
      id
      name
    },
  }`
export default function useFetchGenres(language?: string) {

  const {data, loading, error} = useQuery(GET_GENRES, {variables: {param: {language}}});


  return {res: data ? data.getGenres : [], loading, error};
}