import React, { FC, useState } from 'react';
import { useTranslation } from "react-i18next";
import {  MenuItem, Select, SelectChangeEvent } from "@mui/material";



const LanguageSwitcher: FC = () => {

  const {i18n} = useTranslation();
  const handleChange = (e: SelectChangeEvent<string>) => {
    i18n.changeLanguage(e.target.value);
    setLanguage(e.target.value)
  };
    const [language, setLanguage] = useState<string>(i18n.language.split("-")[0]);
    console.log(i18n.language);
  return (
    <Select
      sx={{color: "white"}}
      value={language}
      label="LN"
      onChange={handleChange}
    >
      <MenuItem value="ru">RU</MenuItem>
      <MenuItem value="en">EN</MenuItem>
    </Select>
  );
};

export default LanguageSwitcher;