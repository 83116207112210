import React, { FC, ReactNode } from 'react';
import { AuthContext, IAuth } from "./AuthContext";

interface IIsLoggedInProviderProps {
  children: ReactNode;
  value: IAuth;
}

const AuthProvider: FC<IIsLoggedInProviderProps> = ({children, value}) => {

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;