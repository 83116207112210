import React, { FC, useState } from 'react';
import {
  ListItemAvatar,
  ListItemText,
  Typography
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { IMovieRes } from "../MoviesList/MoviesList";
import { useTranslation } from "react-i18next";
import { StyledAvatar, StyledListItem, WrapperTextItem } from "./styles";
import MovieOutputButtons from "../MovieOutputButtons";
import MovieAddBtn from "../MovieAddBtn";

const MOVIE_IMAGE_URL = "https://image.tmdb.org/t/p/w500";

export interface IFavoriteMovieItemProps {
  isForOutput: boolean;
  movie: IMovieRes;
  moviesData: IMovieRes[];
  setMoviesData: (value: IMovieRes[]) => void;
  index: number;
}

const ListMovieItem: FC<IFavoriteMovieItemProps> = ({isForOutput, movie, moviesData, setMoviesData, index}) => {
  const {t} = useTranslation();
  const [isWatched, setIsWatched] = useState<boolean>(movie.isWatched);
  const [isFavorite, setIsFavorite] = useState<boolean>(!!movie.userId);

  return (
    <StyledListItem isMarked={((isForOutput && isWatched) || (!isForOutput && isFavorite))}>
      <Typography variant="h4">{index + 1}</Typography>
      <ListItemAvatar>
        <StyledAvatar variant="square"
                      srcSet={MOVIE_IMAGE_URL + movie.data.posterPath}>
          <ImageIcon/>
        </StyledAvatar>
      </ListItemAvatar>
      <WrapperTextItem>
        <ListItemText
          primary={movie.data.title}
          secondary={movie.data.overview}
          sx={{width: "790px"}}
        />
        <Typography sx={{fontWeight: "bold"}}>
          {t("mainPage.rating")}: {movie.data.voteAverage}
        </Typography>
      </WrapperTextItem>
      {isForOutput &&
				<MovieOutputButtons movieInfo={movie.data} moviesData={moviesData}
				                    setMoviesData={setMoviesData}
				                    isWatched={isWatched} setIsWatched={setIsWatched}/>
      }
      {!isForOutput &&
				<MovieAddBtn disabled={isFavorite} setIsFavorite={setIsFavorite}
				             movieId={movie.data.movieId}/>
      }
    </StyledListItem>
  );
};

export default ListMovieItem;