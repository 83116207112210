import React, { FC, useContext, useState } from 'react';
import { Field, FieldRenderProps, Form } from "react-final-form";
import { Button, Typography } from "@mui/material";
import { validate } from "../../utils/validate";
import { useTranslation } from "react-i18next";
import { FormContainer, StyledTextField, Error } from "./styles";
import useSignIn from "../../hooks/useSignIn";
import { AuthContext } from "../../contexts/AuthContext/AuthContext";


const LoginForm: FC = () => {
  const [signIn] = useSignIn()
  const [isError, setIsError] = useState<boolean>(false);
  const {setAuthData} = useContext(AuthContext);
  const {t} = useTranslation();

  const handleSubmit = async (values: { login: string, password: string }) => {
    const {accessToken, user} = await signIn(values.login, values.password);
    if (accessToken) {
      localStorage.setItem("token", accessToken);
      setAuthData({isLoggedIn: true, userLogin: user.login});
    }
    setIsError(true);
  }

  return (
    <FormContainer>
      <Typography variant="h4" component="h2"
                  sx={{textAlign: "center", mb: "2rem"}}>
        {t("authPage.title")}
      </Typography>
      <Form onSubmit={handleSubmit}
            render={({handleSubmit}) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <Field name="login" validate={validate}>
                    {(props: FieldRenderProps<string>) => (
                      <div>
                        <StyledTextField
                          label={t("authPage.login")}
                          name={props.input.name}
                          value={props.input.value}
                          onBlur={props.input.onBlur}
                          onChange={e => {
                            props.input.onChange(e);
                            setIsError(false);
                          }}/>
                        {props.meta.error && props.meta.touched &&
										      <Error>{t("authPage.emptyField")}</Error>}
                      </div>
                    )}
                  </Field>
                </div>
                <div>
                  <Field name="password" validate={validate}>
                    {(props: FieldRenderProps<string>) => (
                      <div>
                        <StyledTextField
                          type="password"
                          label={t("authPage.password")}
                          name={props.input.name}
                          value={props.input.value}
                          onBlur={props.input.onBlur}
                          onChange={e => {
                            props.input.onChange(e);
                            setIsError(false);
                          }}/>
                        {props.meta.error && props.meta.touched &&
										      <Error>{t("authPage.emptyField")}</Error>}
                        {isError &&
										      <Error>{t("authPage.incorrectData")}</Error>}
                      </div>
                    )}
                  </Field>
                </div>
                <Button variant="contained" type="submit"
                        sx={{float: "right"}}>{t("authPage.button")}</Button>
              </form>
            )
            }
      />
    </FormContainer>
  )
    ;
};

export default LoginForm;