import React, { FC, useContext } from 'react';
import { AppBar, Toolbar, Typography } from "@mui/material";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { HeaderLink, StyledLogoutBtn, StyledSignInBtn } from "./styles";
import { AuthContext } from "../../contexts/AuthContext/AuthContext";
import { useApolloClient } from "@apollo/client";

const Header: FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {authData, setAuthData} = useContext(AuthContext);
  const client = useApolloClient();

  const handleLogout: React.MouseEventHandler<HTMLButtonElement> = () => {
    localStorage.clear();
    setAuthData({isLoggedIn: false, userLogin: ""});
    client.clearStore();
    navigate("/auth");
  }

  return (
    <AppBar sx={{color: "white"}} position="static">
      <Toolbar sx={{color: "white"}}>
          <HeaderLink to="/">{t("header.title")}</HeaderLink>
        <LanguageSwitcher/>
        {!authData.isLoggedIn &&
					<StyledSignInBtn sx={{ml: "20px"}} onClick={() => navigate("/auth")}>
            {t("header.signInBtn")}
					</StyledSignInBtn>}
        {authData.isLoggedIn &&
					<Typography sx={{m: "0 10px 0 10px"}}>
            {t("header.greeting") + authData.userLogin}
					</Typography>
        }
        {authData.isLoggedIn &&
					<StyledLogoutBtn onClick={handleLogout}>
            {t("header.logoutBtn")}
					</StyledLogoutBtn>
        }
      </Toolbar>
    </AppBar>
  );
};

export default Header;