import React, { FC } from 'react';
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import useAddFavoriteMovies from "../hooks/useAddFavoriteMovies";

interface IMovieAddBtnProps {
  disabled: boolean;
  setIsFavorite: (value: boolean) => void;
  movieId: number;
}


const MovieAddBtn: FC<IMovieAddBtnProps> = ({disabled, setIsFavorite, movieId}) => {
  const {t} = useTranslation()
  const [addFavoriteMovie] = useAddFavoriteMovies(movieId);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = async () => {
    await addFavoriteMovie()
    setIsFavorite(true);
  }

  return (
    <Button disabled={disabled} variant="outlined"
            onClick={handleClick}>{t("addMovieBtn.save")}</Button>
  );
};

export default MovieAddBtn;