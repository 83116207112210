import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography } from "@mui/material";
import MoviesList, { IMovieRes } from "../components/MoviesList/MoviesList";
import SearchForm from "../components/SearchForm/SearchForm";
import { useTranslation } from "react-i18next";
import useFetchFilters from "../hooks/useFetchFilters";
import useFetchSearchMovies from "../hooks/useFetchSearchMovies";
import useFetchFavoriteGenres from "../hooks/useFetchFavoriteGenres";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext/AuthContext";


const MAX_PAGE_COUNT = 500;

export interface ISearchSettings {
  page?: number;
  year?: string;
  rating?: number;
}

const AddMoviesPage = () => {

  const [filters] = useFetchFilters()
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const [searchSettings, setSearchSettings] = useState<ISearchSettings>({...filters})
  const [moviesData, setMoviesData] = useState<IMovieRes[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const {res: favoriteGenres} = useFetchFavoriteGenres();
  const [searchMoviesRes, loading] = useFetchSearchMovies(searchSettings?.page, searchSettings?.year, searchSettings?.rating, favoriteGenres.join(","), i18n.language);
  const {authData} = useContext(AuthContext);

  useEffect(() => {
    !authData.isLoggedIn && navigate("/auth");
  }, []);

  useEffect(() => {
    if (!loading && searchMoviesRes) {
      setMoviesData(searchMoviesRes.getMoviesList.movies);
      setTotalPages(searchMoviesRes.getMoviesList.totalPages > MAX_PAGE_COUNT ? MAX_PAGE_COUNT : searchMoviesRes.getMoviesList.totalPages)
    }
  }, [searchSettings, searchMoviesRes]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchSettings.year, searchSettings.rating]);

  useEffect(() => {
    setSearchSettings({...searchSettings, page: currentPage});
  }, [currentPage]);

    return (
      <Container>
        <SearchForm favoriteGenres={favoriteGenres} searchSettings={searchSettings} setSearchSettings={setSearchSettings}/>
        {loading && <Typography variant="h5" sx={{textAlign: "center"}}>Loading...</Typography>}
        {!searchMoviesRes && !loading && <Typography variant="h5" sx={{textAlign: "center"}}>Search error</Typography>}
        {(searchMoviesRes && !loading) && <MoviesList totalPages={totalPages} currentPage={currentPage}
                     setCurrentPage={setCurrentPage}
                     moviesData={moviesData} setMoviesData={setMoviesData}
                     headerTitle={t("addMoviesPage.headerTitle")}/>}
      </Container>
    );
};

export default AddMoviesPage;