import React, { FC } from 'react';
import { useTranslation } from "react-i18next";
import { Buttons, FavoriteMovieHeader, FavoriteMoviesTitle, StyledLink } from "./styles";
import SwitchViewBtns from "../SwitchViewBtns";

interface IFavoriteMoviesHeaderProps {
  isForOutput: boolean;
  title: string;
  view: string;
  setView: (value: string) => void;
}

const MoviesListHeader: FC<IFavoriteMoviesHeaderProps> = ({isForOutput, title, view, setView}) => {
  const {t} = useTranslation();

  return (
    <FavoriteMovieHeader>
      <FavoriteMoviesTitle variant="h5">
        {title}
      </FavoriteMoviesTitle>
      <Buttons>
        {isForOutput &&
          <StyledLink to="add">{t("mainPage.addBtn")}</StyledLink>
        }
        <SwitchViewBtns view={view} setView={setView}/>
      </Buttons>
    </FavoriteMovieHeader>
  );
};

export default MoviesListHeader;