import styled from "styled-components";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";


export const StyledLogoutBtn = styled(Button)`
  color: white;
  background-color: #d70505;
`
export const StyledSignInBtn = styled(Button)`
  color: white;
  background-color: #ef4747ff;
`

export const HeaderLink = styled(Link)`
  font-size: 1.5rem;
  flex-grow: 1;
  font-family: Arial;
  text-decoration: none;
  color: white;
`