import { createContext } from "react";

export interface IAuthData {
  isLoggedIn: boolean;
  userLogin: string;
}

export interface IAuth {
  authData: IAuthData
  setAuthData: (value: IAuthData) => void;
}

export const AuthContext = createContext<IAuth>({
  authData: {isLoggedIn: false,
  userLogin: ""},
  setAuthData: () => {}
});