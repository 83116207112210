import React, { FC } from 'react';
import GenresSelect from "../GenresSelect/GenresSelect";
import { Slider, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { StyledForm } from "./styles";
import { ISearchSettings } from "../../pages/AddMoviesPage";

interface ISearchFormProps {
  searchSettings: ISearchSettings;
  setSearchSettings: (value: ISearchSettings) => void;
  favoriteGenres: number[];
}

const SearchForm: FC<ISearchFormProps> = ({favoriteGenres ,searchSettings, setSearchSettings}) => {
  const {t,i18n} = useTranslation();

  const handleSliderChange = (e: Event, newValue: number | number[]) => {
    if (typeof newValue === "number")
      return setSearchSettings({...searchSettings, rating: newValue});
  }

  return (
    <StyledForm>
      <Typography variant="h4" component="h2">{t("searchForm.title")}</Typography>
      <GenresSelect selectedGenres={favoriteGenres}/>
      <Typography sx={{mt: "30px"}}>{t("searchForm.rating")}</Typography>
      <Slider valueLabelDisplay="auto" step={1} min={0} max={10}
              marks={[{value: 0, label: 0}, {value: 10, label: 10}]}
              value={searchSettings.rating || 0}
              onChange={handleSliderChange}/>
      <Typography sx={{mt: "10px"}}>{t("searchForm.year")}</Typography>
      <TextField sx={{width: "100%"}} type="number" value={searchSettings.year}
                 onChange={(e) => setSearchSettings({...searchSettings, year: e.target.value})}/>
    </StyledForm>
  );
};

export default SearchForm;