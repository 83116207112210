import { gql, useMutation } from "@apollo/client";
import { GET_FAVORITE_GENRES } from "./useFetchFavoriteGenres";


export default function useUpdateFavoriteGenres() {

  const UPDATE_FAVORITE_GENRES = gql`
    mutation updateGenres($param: [Float!]!) {
      updateFavoriteGenres(updateFavoriteGenres: $param)
    }
  `
  const [updateGenres, {error}] = useMutation(UPDATE_FAVORITE_GENRES)

  async function updateFavoriteGenres(genres: number[]) {
    return await updateGenres({
      variables: {param: genres},
      refetchQueries: [{query: GET_FAVORITE_GENRES}]
    });
  }

  return {updateFavoriteGenres, error}
}