import React, { FC } from 'react';
import { Box, Grid } from "@mui/material";
import { IMoviesProps } from "./ListViewMovies";
import BlockMovieItem from "./BlockMoiveItem/BlockMovieItem";


const BlockViewMovies: FC<IMoviesProps> = ({isForOutput, moviesData, setMoviesData}) => {
  return (
    <Box sx={{mt: "20px"}}>
      <Grid container spacing={2}>
        {moviesData.map((movie, index) =>
          <BlockMovieItem isForOutput={isForOutput} key={movie.data.movieId + "-" + index} movie={movie} moviesData={moviesData}
                          setMoviesData={setMoviesData} index={index}/>
        )}
      </Grid>
    </Box>
  );
};

export default BlockViewMovies;