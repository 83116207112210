import React, { FC } from 'react';
import Header from "../Header/Header";
import { Outlet } from "react-router-dom";
import { Main, StyledWrapper } from "./styles";


const Layout: FC = () => {
  return (
    <StyledWrapper>
      <Header/>
      <Main>
        <Outlet/>
      </Main>
    </StyledWrapper>

  );
};

export default Layout;