import React, { FC } from 'react';
import { List } from "@mui/material";
import ListMovieItem from "./ListMovieItem/ListMovieItem";
import { IMovie, IMovieRes } from "./MoviesList/MoviesList";

export interface IMoviesProps {
  isForOutput: boolean;
  moviesData: IMovieRes[];
  setMoviesData: (value: IMovieRes[]) => void;
}

const ListViewMovies: FC<IMoviesProps> = ({isForOutput, moviesData, setMoviesData}) => {
  return (
    <List>
      {moviesData && moviesData.map((movie, index) =>
        <ListMovieItem isForOutput={isForOutput} key={movie.data.movieId + "-" + index} movie={movie} moviesData={moviesData}
                       setMoviesData={setMoviesData} index={index}/>
      )}
    </List>
  );
};

export default ListViewMovies;