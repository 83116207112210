import { gql, useMutation } from "@apollo/client";
import { GET_FAVORITE_MOVIES } from "./useFetchFavoriteMovies";
import { GET_SEARCH_MOVIES } from "./useFetchSearchMovies";

export default function useRemoveFavoriteMovie(movieId: number) {
  const REMOVE_FAVORITE_MOVIE = gql`
  mutation removeFavoriteMovie($param: ModifyFavoriteMovieInput!) {
    removeFavoriteMovie(removeFavoriteMovie: $param)
  }
`
  const [removeFavoriteMovie, {data, loading, error}] = useMutation(REMOVE_FAVORITE_MOVIE, {
    variables: {param: {movieId}},
    refetchQueries: [GET_FAVORITE_MOVIES, "getFavoriteMovies", GET_SEARCH_MOVIES, "searchMovies"]
  });

  return [removeFavoriteMovie, data, loading, error];
}