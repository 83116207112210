import React, { FC } from 'react';
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";

interface ISwitchViewBtnsProps {
  view: string;
  setView: (value: string) => void
}

const SwitchViewBtns: FC<ISwitchViewBtnsProps> = ({view, setView}) => {

  const handleChangeView = (
    event: React.MouseEvent<HTMLElement>,
    newView: string
  ) => {
    if (newView)
      setView(newView);
  }

  return (
    <ToggleButtonGroup
      value={view}
      onChange={handleChangeView}
      exclusive
    >
      <ToggleButton value="listView">
        <ViewListIcon/>
      </ToggleButton>
      <ToggleButton value="blockView">
        <ViewModuleIcon/>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default SwitchViewBtns;