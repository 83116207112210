import React, { FC, useState } from 'react';
import {
  Grid,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
} from "@mui/material";
import { IFavoriteMovieItemProps } from "../ListMovieItem/ListMovieItem";
import { useTranslation } from "react-i18next";
import { StyledCard } from "./styles";
import MovieOutputButtons from "../MovieOutputButtons";
import MovieAddBtn from "../MovieAddBtn";

const MOVIE_IMAGE_URL = "https://image.tmdb.org/t/p/w500";

const BlockMovieItem: FC<IFavoriteMovieItemProps> = ({isForOutput, movie, moviesData, setMoviesData}) => {
  const {t} = useTranslation();
  const [isWatched, setIsWatched] = useState<boolean>(movie.isWatched);
  const [isFavorite, setIsFavorite] = useState<boolean>(!!movie.userId);

  return (
    <Grid item xs={4}>
      <StyledCard isMarked={((isForOutput && isWatched) || (!isForOutput && isFavorite))}>
        <CardMedia
          sx={{height: 140}}
          image={MOVIE_IMAGE_URL + movie.data.posterPath}
        />
        <CardContent sx={{flex: '1 1 auto'}}>
          <Typography gutterBottom variant="h5" component="div">
            {movie.data.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {movie.data.overview}
          </Typography>
          <Typography sx={{fontWeight: "bold"}}>
            {t("mainPage.rating")}: {movie.data.voteAverage}
          </Typography>
        </CardContent>
        <CardActions sx={{alignSelf: "flex-end"}}>
          {isForOutput &&
						<MovieOutputButtons movieInfo={movie.data} moviesData={moviesData}
						                    setMoviesData={setMoviesData}
						                    isWatched={isWatched} setIsWatched={setIsWatched}/>}
          {!isForOutput &&
						<MovieAddBtn disabled={isFavorite} setIsFavorite={setIsFavorite}
						             movieId={movie.data.movieId}/>}
        </CardActions>
      </StyledCard>
    </Grid>
  );
};

export default BlockMovieItem;