import React, { FC, useState } from 'react';
import MoviesListHeader from "../MoviesListHeader/MoviesListHeader";
import ListViewMovies from "../ListViewMovies";
import BlockViewMovies from "../BlockViewMovies";
import { StyledPagination } from "./styles";

export interface IMovie {
  movieId: number;
  voteAverage: number;
  title: string;
  overview: string;
  posterPath: string;
}

export interface IMovieRes {
  isWatched: boolean;
  userId: number;
  data: IMovie;
}

interface IMoviesListProps {
  isForOutput?: boolean
  headerTitle: string;
  moviesData: IMovieRes[];
  setMoviesData: (value: IMovieRes[]) => void;
  totalPages: number;
  currentPage: number;
  setCurrentPage: (value: number) => void;
}

const MoviesList: FC<IMoviesListProps> = ({totalPages, currentPage, setCurrentPage, headerTitle,isForOutput = false, moviesData, setMoviesData}) => {

  const [view, setView] = useState<string>("listView");

  return (
    <>
      <MoviesListHeader isForOutput={isForOutput} title={headerTitle} view={view} setView={setView}/>
      {view === "listView" &&
				<ListViewMovies isForOutput={isForOutput} moviesData={moviesData}
				                setMoviesData={setMoviesData}/>
      }
      {view === "blockView" &&
				<BlockViewMovies isForOutput={isForOutput} moviesData={moviesData}
				                 setMoviesData={setMoviesData}/>
      }
      <StyledPagination page={currentPage} count={totalPages} siblingCount={2} boundaryCount={1}
                        onChange={(event: React.ChangeEvent<unknown>, page: number) => setCurrentPage(page)}/>
    </>
  );
};

export default MoviesList;