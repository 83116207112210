import React, { FC } from 'react';

const NotFoundPage: FC = () => {
  return (
    <div>
      404 Страницы не существует
    </div>
  );
};

export default NotFoundPage;